@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/Gilroy/Gilroy-Black.ttf');
  src: url('./fonts/Gilroy/Gilroy-BlackItalic.ttf');
  src: url('./fonts/Gilroy/Gilroy-BoldItalic.ttf');
  src: url('./fonts/Gilroy/Gilroy-ExtraBold.ttf');
  src: url('./fonts/Gilroy/Gilroy-ExtraBoldItalic.ttf');
  src: url('./fonts/Gilroy/Gilroy-Heavy.ttf');
  src: url('./fonts/Gilroy/Gilroy-HeavyItalic.ttf');
  src: url('./fonts/Gilroy/Gilroy-Light.ttf');
  src: url('./fonts/Gilroy/Gilroy-LightItalic.ttf');
  src: url('./fonts/Gilroy/Gilroy-Medium.ttf');
  src: url('./fonts/Gilroy/Gilroy-MediumItalic.ttf');
  src: url('./fonts/Gilroy/Gilroy-RegularItalic.ttf');
  src: url('./fonts/Gilroy/Gilroy-SemiBold.ttf');
  src: url('./fonts/Gilroy/Gilroy-SemiBoldItalic.ttf');
  src: url('./fonts/Gilroy/Gilroy-Thin.ttf');
  src: url('./fonts/Gilroy/Gilroy-ThinItalic.ttf');
  src: url('./fonts/Gilroy/Gilroy-UltraLight.ttf');
  src: url('./fonts/Gilroy/Gilroy-UltraLightItalic.ttf');
  src: url('./fonts/Gilroy/Gilroy-Bold.ttf');
  src: url('./fonts/Gilroy/Gilroy-Regular.ttf');
}


