body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
}

@page {
  size: A4;
  margin: 5mm 9mm 9mm 9mm;
}

